import React from "react"

function copyright() {
  return new Date().getFullYear()
}

export const Footer = () => {
  return (
    <div className="bg-gray-50">
      <div className="text-center text-3xl font-extrabold mx-auto">
        <h3 className="prose text-3xl">Certifications</h3>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.nachi.org/verify?nachiid=NACHI18110615" target="_blank" rel="noreferrer">
              <img src="http://www.nachi.org/webseals/s-v2-NACHI18110615.gif" width="98" height="102" alt="Certified by the International Association of Certified Home Inspectors" border="0" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.nachi.org/verify?nachiid=NACHI18110615" target="_blank" rel="noreferrer">
              <img src="http://www.nachi.org/webseals/internachi-honor-guarantee.png?nachiid=NACHI18110615" width="170" height="170" alt="$10,000 Honor Guarantee, Backed by InterNACHI" border="0" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mx-auto">
        <p className="prose text-xs">Elite Home Inspections, LLC. &copy; {copyright()}</p>
      </div>
    </div>
  )
}
