import React from "react"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const QuoteForm = () => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate("/thank-you/"))
      .catch(error => alert(error))
  }

  return (
    <div className="bg-white px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Request a Quote
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            We would to love to assist you in your next investment. For a Quote
            for your potential property please fill out the Quote Form below and
            we will be in touch soon.
          </p>
        </div>
        <div className="mt-12">
          <form
            name="quote"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
          >
            <input type="hidden" name="form-name" value="quote" />
            <input type="hidden" name="bot-field" onChange={handleChange} />
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Full Name
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="name"
                    name="name"
                    onChange={handleChange}
                    className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                  />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Company
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="company"
                    name="company"
                    onChange={handleChange}
                    className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                  />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                  />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Phone Number
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="phone_number"
                    name="phone_number"
                    onChange={handleChange}
                    className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    placeholder="+1 (555) 987-6543"
                  />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="property_address"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Full Address of Property
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="property_address"
                    name="property_address"
                    onChange={handleChange}
                    className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    placeholder="1234 Acme Lane, Happyville, KY 41111"
                  />
                </div>
              </label>
            </div>
            <div>
              <label
                htmlFor="home_age"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Age of Home
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="home_age"
                    name="home_age"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div>
              <label
                htmlFor="square_footage"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Home Square Footage
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="square_footage"
                    name="square_footage"
                    required
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onChange={handleChange}
                  />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Message
                <div className="mt-1 relative rounded-md shadow-sm">
                  <textarea
                    required
                    id="message"
                    name="message"
                    rows="4"
                    onChange={handleChange}
                    className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"
                  ></textarea>
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <span className="w-full inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  Let's talk
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
