import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import logo from "../images/logo.png"

export const Seo = ({ description, title, image, url, keywords }) => {
  const pageTitle = title
    ? `Elite Home Inspection | ${title}`
    : "Elite Home Inspection"
  const pageUrl =
    typeof window !== "undefined"
      ? window.location.href
      : "https://elitehome.biz"
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description
          ? description
          : data.site.siteMetadata.description
        const metaTitle = pageTitle || "Elite Home Inspection"
        const metaImage = logo || ""
        const metaUrl = url ? data.site.siteMetadata.url : pageUrl
        const metaKeywords = keywords
          ? keywords
          : [
              "Home Inspections",
              "Home Inspections Central Kentucky",
              "Home Inspections Central KY",
            ]

        return (
          <Helmet
            title={title}
            defer={false}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          >
            <title>{pageTitle}</title>
          </Helmet>
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        image
        url
      }
    }
  }
`
