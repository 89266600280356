import React from "react"
import tw from "twin.macro"
import { Seo } from "./Seo"
import { Footer } from "./Footer"

const PageContainer = tw.div`
  relative bg-white overflow-hidden
`

export const Layout = ({ title, children }) => (
  <>
    <Seo title={title} />
    <PageContainer>{children}</PageContainer>
    <Footer />
  </>
)
